<template>
  <div>
    <el-tabs v-model="type" @tab-click="getList">
      <el-tab-pane v-for="(type, i) in taxonomy" :label="type[isEn + 'name']" :name="type.value"></el-tab-pane>
    </el-tabs>
    <el-button type="primary" size="mini" @click="createForm">{{$t('button.add')}}</el-button>
    <el-button type="danger" :disabled="disabled" size="mini" @click="removeCategory(deleteCategory)">{{$t('button.del')}}</el-button>
    <el-row>
      <el-col :span="24" class="mt20">
        <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="list"
            @selection-change="categoryChange"
            size="small"
            row-key="id"
            tooltip-effect="dark">
          <!--<el-table-column-->
          <!--    type="expand">-->
          <!--</el-table-column>-->
          <el-table-column
              type="selection">
          </el-table-column>
          <el-table-column
              prop="id"
              label="ID">
          </el-table-column>
          <el-table-column
              :prop="isEn + 'name'"
              :label="$t('page.productType.category')">
          </el-table-column>
          <el-table-column
              :prop="isEn + 'desc'"
              :label="$t('page.productType.desc')">
          </el-table-column>
          <el-table-column
              prop="order"
              :label="$t('page.productType.order')">
          </el-table-column>
          <!--<el-table-column
              label="状态">
            <template v-slot="scope">
              <el-switch
                  v-model="scope.row.status">
              </el-switch>
            </template>
          </el-table-column>-->
          <el-table-column
              prop="username"
              :label="$t('page.productType.create_user')">
          </el-table-column>
          <el-table-column
              prop="create_time"
              :label="$t('page.productType.create_time')"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="update_time"
              :label="$t('page.productType.update_time')"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              width="200"
              :label="$t('action')">
            <template v-slot="scope">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="getCategory(scope.row)">{{$t('button.edit')}}</el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeCategory(scope.row.id)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!--<el-pagination
        class="mt20"
        :hide-on-single-page="onePage"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @size-change="categorySizeChange"
        @current-change="categoryCurrentChange"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>-->

    <!-- 添加分类对话框 -->
    <el-dialog
        :title="saveData.id ? $t('button.edit') : $t('button.add')"
        :visible.sync="addDialogVisible"
        @close="dialogClose('saveTaxonomy')"
        width="50%">
      <create-form
          ref="saveTaxonomy"
          size='medium'
          :createData="saveData"
          :createForm="saveForm"
          :rule="fromRules">
      </create-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="save">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'; // 深拷贝
import config from '@/config';
import {saveTaxonomy, getTaxonomy, getTaxonomyAll, removeTaxonomy} from '@/api/os/admin';
import CreateForm from '@/components/Form';

export default {
  name: 'console-category',
  data() {
    return {
      type: config.default.taxonomyIndex,
      taxonomy: config.default.taxonomy,
      list: [],
      addDialogVisible: false,
      loading: true,
      pageSize: config.default.pageLimit,        // 分页大小
      pageSizes: config.default.pageSizes,
      currentPage: 1,     // 当前页
      total: 0,
      query: '',
      onePage: false,
      // 添加分类
      saveData:{
        name: '',
        desc: ''
        // status: true
      },
      // 删除数据
      deleteCategory: '',
      disabled: true,
      selected: 0,
      propsTaxonomy: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: true
      },
    }
  },
  components: {
    CreateForm
  },
  created() {
    this.getList()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
    // add
    saveForm() {
      return [
        {
          type: 'Input',
          label: this.$t('form.name'),
          prop: 'name',
          placeholder: this.$t('form.name')
        },
        {
          type: 'Input',
          label: this.$t('form.name') + '-en',
          prop: 'en_name',
          placeholder: this.$t('form.name') + '-en'
        },
        {
          type: 'textarea',
          label: this.$t('form.desc'),
          prop: 'desc',
          row: '5',
          max: 30,
          placeholder: this.$t('form.desc')
        },
        {
          type: 'Cascader',
          label: this.$t('form.select_taxonomy'),
          prop: 'fid',
          options: this.list,
          props: this.propsTaxonomy,
          placeholder: '选择父级'
        },
        {
          type: 'Input',
          label: this.$t('form.order'),
          prop: 'order',
          placeholder: '越大越靠前'
        },
        {
          type: 'textarea',
          label: this.$t('form.desc') + '-en',
          prop: 'en_desc',
          row: '5',
          max: 30,
          placeholder: this.$t('form.desc') + '-en'
        },
        // {
        //   type: 'Switch',
        //   label: this.$t('form.category.add.status'),
        //   prop: 'status'
        // },
        {
          type: 'Image',
          label: this.$t('button.upload_image'),
          prop: 'thumbnail'
        }
      ]
    },
    // 表单验证
    fromRules() {
      const rules = {
        name: [
          { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('validate.max_length') + '2~10', trigger: 'blur' }
        ],
      }
      return rules
    }
  },
  methods: {
    getList() {
      this.loading = true
      let data = {
        // page: this.currentPage,
        // pageSize: this.pageSize,
        query: this.query,
        type: this.type
      }
      getTaxonomyAll(data).then(res => {
        let data = res.data.data;
        this.list = data;
        // this.total = res.data.data.total
        this.loading = false;
        if(this.total <= this.pageSize) {
          this.onePage = true
        }
      })
    },
    getCategory(item) {
      this.addDialogVisible = true
      // 深拷贝对象
      this.saveData = _.cloneDeep(item)
    },
    // 删除选中的id
    removeCategory(id) {
      if(!id) {
        this.$message.error(this.$t('tips.not_selected'))
        return
      }
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeTaxonomy(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.getList()
          }
        })
      })
    },
    // 关闭对话框
    dialogClose(ref) {
      // this.$refs[ref].resetFields()
      this.$nextTick(() => {
        this.$refs[ref].clearValidate()
      })
    },
    createForm() {
      this.addDialogVisible = true;
      this.saveData = {}
    },

    async save() {
      let valid = await this.$refs.saveTaxonomy.validate()
      if( !valid ) return;
      let data = this.saveData;
      data.type = this.type
      if(data.fid instanceof Array) {
        let index = data.fid.length
        data.fid = data.fid[index - 1]
      }
      if(data.id && data.id == data.fid) {
        return this.$message.warning('不能选择当前分类')
      }
      saveTaxonomy(data).then(res => {
        this.addDialogVisible = false
        this.$message.success(this.$t('tips.success'))
        this.getList()
      })
    },

    // 选中的分类
    categoryChange(selection) {
      let data = []
      selection.forEach((v, i) => {
        data.push(v.id)
      })
      this.selected = data.length
      this.deleteCategory = data.join(',')

      if(this.deleteCategory) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },

    // 分页
    categorySizeChange(pagesize) {
      this.pageSize = pagesize
      this.getList()
    },
    categoryCurrentChange(page) {
      this.currentPage = page
      this.getList()
    }
  }
}
</script>